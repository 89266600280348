import logo from "./Relevant.svg";
import "./App.css";

function App() {
  // Redirect immediately
  window.location.replace(
    "https://billing.stripe.com/p/login/9AQ8wKeelaAT0HS4gg"
  );

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
    </div>
  );
}

export default App;
